@font-face {
  font-family: 'Radikal';
  src: url('./fonts/radikal/Radikal-Medium.woff2') format('woff2'),
    url('./fonts/radikal/Radikal-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('./fonts/radikal/Radikal-Bold.woff2') format('woff2'),
    url('./fonts/radikal/Radikal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('./fonts/radikal/Radikal-Regular.woff2') format('woff2'),
  url('./fonts/radikal/Radikal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CampusFranceSans';
  src: url('./fonts/campusfrance/sans/campusfrance-bold.woff2') format('woff2'),
    url('./fonts/campusfrance/sans/campusfrance-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}