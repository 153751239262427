@font-face {
  font-family: 'Radikal';
  src: url(/static/media/Radikal-Medium.3e3c9e26.woff2) format('woff2'),
    url(/static/media/Radikal-Medium.8dde7724.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url(/static/media/Radikal-Bold.a30a8db9.woff2) format('woff2'),
    url(/static/media/Radikal-Bold.d7cff29b.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url(/static/media/Radikal-Regular.71a97a66.woff2) format('woff2'),
  url(/static/media/Radikal-Regular.9ea8aec7.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CampusFranceSans';
  src: url(/static/media/campusfrance-bold.661505cf.woff2) format('woff2'),
    url(/static/media/campusfrance-bold.b1a6df68.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/** ICON */
#tarteaucitronIcon {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

#tarteaucitronIcon #tarteaucitronManager {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  background-image: url(/static/media/icon-cookies1.6a772bac.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  transition: opacity 120ms;
}

#tarteaucitronIcon #tarteaucitronManager:hover {
  opacity: 1;
}

#tarteaucitronIcon #tarteaucitronManager img {
  display: none;
}

/** ROOT */
#tarteaucitronRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1600;
  background-color: transparent;
  transition: background-color 250ms;
}

#tarteaucitronRoot a {
  color: #E1000F;
}

/** Modale personaliser */
.tarteaucitron-modal-open #tarteaucitronRoot {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: unset;
  right: unset;
  background-color: rgba(0, 0, 0, 0.4);
}

.tarteaucitron-modal-open #tarteaucitron {
  display: flex !important;
}

body:not(.tarteaucitron-modal-open) .tarteaucitronBeforeVisible #tarteaucitronAlertBig {
  display: flex !important;
}

.tarteaucitron-modal-open #tarteaucitronAlertBig {
  display: none !important;
}

#tarteaucitron {
  width: 50%;
  height: auto;  
  top: 0 !important;
  display: none;
  flex-direction: column;
  padding: 32px;
  position: relative;
  overflow: hidden;
  background-color: white;
}

#tarteaucitronClosePanel {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  align-self: flex-end;
  border: none;
  outline: none;
  font-size: 0;
  background-color: unset;
  background-image: url(/static/media/icon-close.12fef809.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

#tarteaucitronServices {
  margin-top: 16px;
  overflow-x: initial !important;
}

#tarteaucitronMainLineOffset .tarteaucitronAsk {
  display: flex;
  justify-content: flex-end;
}

#tarteaucitronMainLineOffset .tarteaucitronAsk button:last-child {
  margin-left: 16px;
}

#tarteaucitronMainLineOffset .tarteaucitronAsk button,
#tarteaucitronAlertBig .tarteaucitronCTAButton {
  outline: none;
  border: none;
  padding: 16px;
  color: #FFFFFF;
  background-color: #E1000F;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 500;
  transition: background-color 125ms ease-out;
}

#tarteaucitronMainLineOffset .tarteaucitronAsk button:hover,
#tarteaucitronAlertBig .tarteaucitronCTAButton.tarteaucitronAllow:hover {
  background-color: #F34550;
  cursor: pointer;
}

#tarteaucitronMainLineOffset .tarteaucitronAsk button#tarteaucitronAllDenied,
#tarteaucitronAlertBig .tarteaucitronCTAButton.tarteaucitronDeny {
  background-color: #262773;
}

#tarteaucitronMainLineOffset .tarteaucitronAsk button#tarteaucitronAllDenied:hover,
#tarteaucitronAlertBig .tarteaucitronCTAButton.tarteaucitronDeny:hover {
  background-color: #8381af;
  cursor: pointer;
}

#tarteaucitronServices {
  height: 100% !important;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

#tarteaucitronServices .tarteaucitronBorder {
  height: auto;
  flex: 1 1;
  overflow-y: auto;
}

#tarteaucitronServices .tarteaucitronBorder ul {
  list-style: none;
  padding: unset;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronTitle,
#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronDetails {
  display: none;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine {
  display: flex;
  flex-direction: row-reverse;
  color: #262773;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronName {
  flex: 1 1;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronName .tarteaucitronH3 {
  font-size: 1.5rem;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronName .tacCurrentStatus {
  font-weight: bold;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronName .tacCurrentStatus::before {
  content: '(';
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronName .tacCurrentStatus::after {
  content: ')';
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronAsk {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding-right: 32px;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronAsk button {
  width: 25px;
  height: 25px;
  outline: none;
  border: none;
  margin-bottom: 8px;
  font-size: 0;
  line-height: 1;
  color: white;
  background-color: #E1000F;
  transition: opacity 0.3s ease-out;
}

#tarteaucitronServices .tarteaucitronHidden .tarteaucitronLine:not(.tarteaucitronIsAllowed) .tarteaucitronAllow,
#tarteaucitronServices .tarteaucitronHidden .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.2;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronAsk button:hover {
  opacity: 0.7;
  cursor: pointer;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronAsk button.tarteaucitronAllow::after {
  content: '\2713';
  font-size: 1rem;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronAsk button.tarteaucitronDeny {
  background-color: #262773;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronAsk button.tarteaucitronDeny:hover {
  opacity: 0.7;
}

#tarteaucitronServices .tarteaucitronBorder ul .tarteaucitronHidden .tarteaucitronLine .tarteaucitronAsk button.tarteaucitronDeny::after {
  content: '\2715';
  font-size: 1rem;
}

#tarteaucitronServices .tarteaucitronBorder #tarteaucitronServicesTitle_mandatory .tarteaucitronTitle {
  font-size: 32px;
  color: #E1000F;
  line-height: 2rem;
  text-transform: uppercase;
  margin-bottom: 32px;
}

/** Bandeau */
#tarteaucitronAlertBig {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 64px 128px;
  outline: none;
  background-color: white;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.3);
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
  /*font*/
  color: #262773;
}

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert a,
#tarteaucitronCustomiseLink {
  color: #E1000F;
}

#tarteaucitronCustomiseLink {
  text-decoration: underline;
  cursor: pointer;
}

#tarteaucitronAlertBig .tarteaucitronAllow {
  margin-left: auto;
  margin-right: 16px;
}

/** Hide */
.tac_visually-hidden, #tarteaucitronPremium, #tarteaucitronBack, #tarteaucitronInfo,
#tarteaucitronMainLineOffset .tarteaucitronName, #tarteaucitronMainLineOffset .tarteaucitronH1,
.tarteaucitronHidden, .tarteaucitronReadmoreSeparator, .tarteaucitronListCookies,
.tarteaucitronReadmoreOfficial, .tarteaucitronReadmoreSeparator {
  display: none;
}


/** Mobile */
.mobile #tarteaucitron {
  width: calc(100% - 32px);
  height: auto;
}

/** Bandeau */
.mobile #tarteaucitronAlertBig {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
}

.mobile #tarteaucitronAlertBig .tarteaucitronCTAButton {
  margin: auto;
  margin-top: 16px;
}

.mobile #tarteaucitronIcon {
  display: none !important;
}
